/*
    IMPORTANT NOTES: (PLEASE READ CAREFULLY!)
    1. DO NOT MODIFY the system styles section, it is generated by the system,
    your modifications will be overwritten. If you want to change the stylesheet
    template, modify the ew.scss in the "themes" subfolder under the installed
    directory. Only modify the ew.scss if you have the necessary knowledges in
    CSS.
    2. You MUST write your styles in User Styles editor. If you want to override
    the system styles, you can copy the styles to the User Styles editor, or you
    can use the separate user stylesheet.
*/
/*BEGIN_SYSTEM_STYLES*/
@keyframes flipInX {
  0% {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transition-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transition-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  100% {
    transform: perspective(400px);
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes shake {
  0% {
    transform: translate(2px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-2deg);
  }
  20% {
    transform: translate(-3px, 0) rotate(3deg);
  }
  30% {
    transform: translate(0, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(2px, 1px) rotate(-2deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(4deg);
  }
  90% {
    transform: translate(2px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
@keyframes wobble {
  0% {
    transform: none;
  }
  15% {
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }
  30% {
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }
  45% {
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }
  60% {
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }
  75% {
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }
  100% {
    transform: none;
  }
}
:root {
  --modal-options-limit: 10;
  --filter-options-limit: 6;
  --select2-options-limit: 10;
}

@font-face {
  font-family: "ew";
  src: url("fonts/ew.eot?t39no9");
  src: url("fonts/ew.eot?#iefixt39no9") format("embedded-opentype"), url("fonts/ew.woff?t39no9") format("woff"), url("fonts/ew.ttf?t39no9") format("truetype"), url("fonts/ew.svg?t39no9#ew") format("svg");
  font-weight: normal;
  font-style: normal;
}
[class^=icon-], [class*=" icon-"] {
  display: inline-block;
  font: normal normal normal 14px/1 "ew";
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-advanced-search::before {
  content: "\e608";
}

.icon-grid-add::before {
  content: "\e60f";
}

.icon-grid-edit::before {
  content: "\e61c";
}

.icon-addedit::before {
  content: "\e609";
}

.icon-md-add::before {
  content: "\e61b";
}

.icon-md-edit::before {
  content: "\e600";
}

.icon-md-copy::before {
  content: "\e60c";
}

.icon-md-view::before {
  content: "\e614";
}

.icon-inline-add::before {
  content: "\e619";
}

.icon-inline-copy::before {
  content: "\e606";
}

.icon-inline-copy2::before {
  content: "\e61a";
}

.icon-inline-edit::before {
  content: "\e605";
}

.icon-reset-search::before {
  content: "\e60d";
}

.icon-view::before {
  content: "\e612";
}

.icon-advanced-search2::before {
  content: "\e60b";
}

.icon-highlight::before {
  content: "\e60a";
}

.icon-csv::before {
  content: "\e610";
}

.icon-xml::before {
  content: "\e60e";
}

.icon-email::before {
  content: "\f003";
}

.icon-user::before {
  content: "\f007";
}

.icon-print::before {
  content: "\f02f";
}

.icon-edit::before {
  content: "\f040";
}

.icon-export::before {
  content: "\f045";
}

.icon-selected::before {
  content: "\f046";
}

.icon-filter::before {
  content: "\f0b0";
}

.icon-copy::before {
  content: "\f0c5";
}

.icon-options::before {
  content: "\f0c9";
}

.icon-table::before {
  content: "\f0ce";
}

.icon-first::before {
  content: "\f100";
}

.icon-last::before {
  content: "\f101";
}

.icon-prev::before {
  content: "\f104";
}

.icon-next::before {
  content: "\f105";
}

.icon-collapse::before {
  content: "\f147";
}

.icon-expand::before {
  content: "\f196";
}

.icon-search::before {
  content: "\e611";
}

.icon-language::before {
  content: "\e613";
}

.icon-pdf::before {
  content: "\e601";
}

.icon-word::before {
  content: "\e602";
}

.icon-excel::before {
  content: "\e603";
}

.icon-html::before {
  content: "\e604";
}

.icon-master-detail::before {
  content: "\e607";
}

.icon-arrow-left::before {
  content: "\e615";
}

.icon-arrow-down::before {
  content: "\e616";
}

.icon-arrow-up::before {
  content: "\e617";
}

.icon-arrow-right::before {
  content: "\e618";
}

body {
  font-family: "JF Flat", var(--bs-font-sans-serif), "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  line-height: 1.5;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: "JF Flat", var(--bs-font-sans-serif), "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.content-wrapper {
  background-color: #FFFFFF;
}

a:not(.btn) .icon-view,
a:not(.btn) .icon-edit,
a:not(.btn) .icon-inline-edit,
a:not(.btn) .icon-copy,
a:not(.btn) .icon-inline-copy,
a:not(.btn) .fa-trash {
  display: inline-block;
}
a:not(.btn).ew-edit + a:not(.btn).ew-inline-edit, a:not(.btn).ew-copy + a:not(.btn).ew-inline-copy {
  padding-left: 4px;
}

/* google map */
.gm-style-iw {
  color: #212529;
}

.ew-single-map {
  margin-bottom: 1rem;
  max-width: 100%;
  height: auto;
}

.brand-link .ew-brand-image {
  float: none;
}

.ew-icon {
  font-size: max(0.875rem, 1rem);
  width: max(0.875rem, 1rem);
  text-align: center;
}

.input-group .ew-icon, .ew-dropdown-clear .ew-icon {
  font-size: 0.875rem;
  width: 0.875rem;
  text-align: center;
}

.dropdown-menu > li > a > .fa {
  margin-right: 0;
}

.dropdown-header {
  text-align: left;
}

label.form-check-label {
  font-weight: 400 !important;
}

[id^=el_] > .form-check {
  margin-top: 0.5rem;
}

.ew-grid-add-edit .input-group .custom-select {
  width: auto;
}

.content-header h1 small {
  font-size: 70%;
}

.ew-image {
  margin: 1px;
  max-width: none;
}

.ew-images > * {
  margin-right: 0.25rem;
}

.ew-toolbar .ew-export-option, .ew-toolbar .ew-import-option, .ew-toolbar .ew-search-option, .ew-toolbar .ew-action-option, .ew-toolbar .ew-detail-option, .ew-toolbar .ew-filter-option {
  margin-bottom: 1rem;
  margin-right: 0.5rem;
}
.ew-toolbar .ew-filter-option {
  display: none;
}

.ew-list-other-options .ew-add-edit-option, .ew-list-other-options .ew-acton-option {
  margin-right: 0.5rem;
}

.ew-user-priv-form .ew-grid {
  display: block;
}

.ew-view-form, .ew-delete-form {
  display: block;
  margin-bottom: 1rem;
}

.ew-edit-form {
  margin-bottom: 1rem;
}

.ew-desktop .ew-desktop-buttons {
  text-align: center;
  margin-bottom: 1rem;
}
.ew-desktop .ew-desktop-table {
  min-width: 300px;
}
.ew-desktop .ew-desktop-table > thead > tr > th:first-of-type .checkbox, .ew-desktop .ew-desktop-table > tbody > tr > th:first-of-type .checkbox {
  margin-top: 0;
  margin-bottom: 0;
}
.ew-desktop .ew-desktop-table > thead > tr > td:first-of-type > span[id^=elh_], .ew-desktop .ew-desktop-table > tbody > tr > td:first-of-type > span[id^=elh_] {
  font-weight: bold;
  display: inline-block;
  margin-top: 0.75rem;
}
.ew-desktop .ew-desktop-table > thead > tr > td:first-of-type .checkbox, .ew-desktop .ew-desktop-table > tbody > tr > td:first-of-type .checkbox {
  margin-top: 0;
  margin-bottom: 0;
}
.ew-desktop .ew-desktop-table > thead > tr > td:first-of-type .checkbox label, .ew-desktop .ew-desktop-table > tbody > tr > td:first-of-type .checkbox label {
  font-weight: bold;
}
.ew-desktop .ew-desktop-table > thead > tr > td:nth-of-type(2):not(:last-of-type) .ew-search-operator, .ew-desktop .ew-desktop-table > tbody > tr > td:nth-of-type(2):not(:last-of-type) .ew-search-operator {
  margin-top: 0.75rem;
}
.ew-desktop .ew-desktop-table > thead > tr > th:last-of-type, .ew-desktop .ew-desktop-table > thead > tr > td:last-of-type, .ew-desktop .ew-desktop-table > tbody > tr > th:last-of-type, .ew-desktop .ew-desktop-table > tbody > tr > td:last-of-type {
  min-width: 150px;
}

.ew-label .checkbox {
  padding-top: 0;
}
.ew-label label {
  font-weight: bold;
}

.ew-grid {
  padding: 0;
  border: 1px solid;
  margin-bottom: 1rem;
  display: table;
}
.ew-grid .ew-table.table-bordered > thead > tr:first-child {
  border-top-width: 0;
}
.ew-grid .ew-table, .ew-grid .ew-grid-middle-panel {
  border: 0;
  padding: 0;
  margin-bottom: 0;
}

body:not(.dark-mode) .ew-grid {
  border-color: #467B99;
}
body:not(.dark-mode) .ew-grid.ew-loading {
  border-color: rgba(70, 123, 153, 0.25);
}
body:not(.dark-mode) .ew-grid .ew-grid-upper-panel {
  border-bottom: 1px solid #508BAD;
}
body:not(.dark-mode) .ew-grid .ew-table tbody,
body:not(.dark-mode) .ew-grid .ew-table tfoot,
body:not(.dark-mode) .ew-grid .ew-table :not(thead) > tr,
body:not(.dark-mode) .ew-grid .ew-table td,
body:not(.dark-mode) .ew-grid .ew-table th {
  border-color: #DEE2E6;
}
body:not(.dark-mode) .ew-grid .ew-table > thead > *,
body:not(.dark-mode) .ew-grid .ew-table > thead > * > * {
  border-bottom-color: #508BAD !important;
}
body:not(.dark-mode) .ew-grid .ew-table > :not(:last-child):not(thead) > :last-child > * {
  border-bottom-color: #DEE2E6;
}
body:not(.dark-mode) .ew-grid .ew-grid-lower-panel {
  border-top: 1px solid var(--bs-border-color);
}

.ew-form .row {
  margin-bottom: 1rem;
}

.ew-search-panel > .card {
  display: table;
}
.ew-search-panel .row-cols-sm-1 > .col-sm-auto {
  width: 100%;
}

.ew-pager {
  padding-right: 1rem;
  margin-bottom: 1rem;
  display: inline-flex;
  width: auto;
  align-items: center;
  /*rtl:ignore*/
  direction: ltr;
}
.ew-pager .input-group {
  flex-wrap: nowrap;
}
.ew-pager .input-group > input.ew-page-number {
  width: 6em;
}
.ew-pager .ew-prev-next, .ew-pager .ew-numeric-page {
  direction: ltr;
}
.ew-pager .ew-prev-next .btn:not(:disabled), .ew-pager .ew-numeric-page .btn:not(:disabled) {
  cursor: pointer;
}
.ew-pager .ew-numeric-page > ul.pagination {
  margin-bottom: 0;
}

.ew-prev-next {
  display: flex;
}

.ew-list-other-options {
  margin-bottom: 2px;
  display: inline-flex;
}

.ew-grid-upper-panel.card-header, .ew-grid-lower-panel.card-footer {
  padding: 0.4rem 0.4rem;
  border-left: 0;
  border-right: 0;
  background-color: rgba(0, 0, 0, 0.03);
}
.ew-grid-upper-panel.card-header .ew-pager, .ew-grid-lower-panel.card-footer .ew-pager {
  margin-bottom: 0;
}

button.ew-btn {
  min-width: 75px;
}

a.ew-btn {
  min-width: 49px;
}

.ew-btn-dropdown {
  min-width: calc(0.25rem * 2 + max(0.875rem, 1rem) + 0.3em * 2 + 2px);
}

.ew-aggregate::after {
  content: ": ";
}

.ew-table-header .ew-table-header-btn {
  white-space: nowrap;
  display: flex;
}
.ew-table-header .ew-table-header-btn .ew-table-header-caption {
  flex-grow: 1;
}
.ew-table-header .ew-table-header-btn .ew-filter-dropdown-btn, .ew-table-header .ew-table-header-btn .ew-table-header-sort:not(:empty) {
  margin-left: 0.5rem;
}

.ew-summary-caption .ew-table-header-btn {
  white-space: nowrap;
  display: inline-flex;
}
.ew-summary-caption .ew-table-header-btn .ew-filter-dropdown-btn, .ew-summary-caption .ew-table-header-btn .ew-table-header-sort:not(:empty) {
  margin-left: 0.25rem;
}

.ew-grid .ew-table {
  border: 0;
  width: 100%;
}
.ew-grid .ew-table.table > tbody {
  border-top: 0;
}
.ew-grid .ew-table > tbody:last-child > tr:last-child,
.ew-grid .ew-table > tbody:last-child > tr:last-child > td,
.ew-grid .ew-table > tfoot,
.ew-grid .ew-table > tfoot > tr:last-child,
.ew-grid .ew-table > tfoot > tr:last-child > td,
.ew-grid .ew-table td.ew-table-last-row {
  border-bottom: 0;
}
.ew-grid .ew-table > tbody:last-child > tr:last-child > td.ew-table-border-bottom,
.ew-grid .ew-table > tfoot > tr:last-child > td.ew-table-border-bottom,
.ew-grid .ew-table td.ew-table-last-row.ew-table-border-bottom,
.ew-grid .ew-table .ew-table-border-bottom {
  border-bottom: 1px solid;
}
.ew-grid .ew-table > thead > tr > th,
.ew-grid .ew-table > thead > tr > td {
  vertical-align: top;
}
.ew-grid .ew-table > thead > tr > th label,
.ew-grid .ew-table > thead > tr > td label {
  margin-bottom: 0;
}
.ew-grid .ew-table .table-active {
  --bs-table-bg: null;
  --bs-table-accent-bg: null;
}
.ew-grid .ew-table > thead > tr > td:first-child,
.ew-grid .ew-table > thead > tr > th:first-child,
.ew-grid .ew-table > tbody > tr > td:first-child,
.ew-grid .ew-table > tfoot > tr > td:first-child {
  border-left: 0;
}
.ew-grid .ew-table > thead > tr > td:last-child,
.ew-grid .ew-table > thead > tr > th:last-child,
.ew-grid .ew-table > tbody > tr > td:last-child,
.ew-grid .ew-table > tfoot > tr > td:last-child,
.ew-grid .ew-table td.ew-table-last-col, .ew-grid .ew-table th.ew-table-last-col {
  border-right: 0;
}

body:not(.dark-mode) .ew-grid .ew-table > tbody:last-child > tr:last-child > td.ew-table-border-bottom,
body:not(.dark-mode) .ew-grid .ew-table > tfoot > tr:last-child > td.ew-table-border-bottom,
body:not(.dark-mode) .ew-grid .ew-table td.ew-table-last-row.ew-table-border-bottom,
body:not(.dark-mode) .ew-grid .ew-table .ew-table-border-bottom {
  border-color: var(--bs-border-color);
}
body:not(.dark-mode) .ew-grid .ew-table > thead > tr > th,
body:not(.dark-mode) .ew-grid .ew-table > thead > tr > td {
  font-weight: normal;
  border-color: #508BAD;
}
body:not(.dark-mode) .ew-grid .ew-table .ew-table-header, body:not(.dark-mode) .ew-grid .ew-table .ew-rpt-col-summary, body:not(.dark-mode) .ew-grid .ew-table .ew-rpt-col-header {
  --bs-table-accent-bg: #5797BC;
  color: #000000;
}
body:not(.dark-mode) .ew-grid .ew-table .ew-table-footer {
  --bs-table-accent-bg: #E7F1F7;
  color: var(--bs-table-color);
}
body:not(.dark-mode) .ew-grid .ew-table .table-active {
  --bs-table-accent-bg: #DFEDF5;
  color: var(--bs-table-color);
}
body:not(.dark-mode) .ew-grid .ew-table tr:not([class^=ew-]):not(.table-active) {
  --bs-table-accent-bg: transparent;
  color: var(--bs-table-color);
}
body:not(.dark-mode) .ew-grid .ew-table .ew-table-alt-row:not(.table-active) {
  --bs-table-accent-bg: rgba(0, 0, 0, 0.05);
  color: var(--bs-table-color);
}
body:not(.dark-mode) .ew-grid .ew-table.table-hover > tbody > tr:not(.expandable-body):hover, body:not(.dark-mode) .ew-grid .ew-table.table-hover > tbody > tr:not(.expandable-body):hover > * {
  --bs-table-accent-bg: rgba(0, 0, 0, 0.075);
  color: var(--bs-table-color);
}

.expandable-body .ew-table {
  margin: 0;
}

.ew-add-edit-option {
  display: inline-block;
}

.ew-detail-option {
  display: inline-block;
}

.ew-action-option {
  padding-right: 10px;
  display: inline-block;
}

.ew-multi-column-form > .ew-multi-column-row {
  margin-bottom: 1rem;
  position: relative;
}
.ew-multi-column-form > .ew-multi-column-row .card .ew-table-header-btn {
  display: inline-flex;
}
.ew-multi-column-form > .ew-multi-column-row .card .ew-table-header-btn .ew-table-header-sort {
  margin-left: 0.5rem;
}
.ew-multi-column-form > .ew-multi-column-row .card-header + * .card-img-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.ew-multi-column-form > .ew-multi-column-row .card > *:not(:last-child) .card-img-bottom {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.ew-multi-column-form > .ew-multi-column-row .card-body .card-title {
  float: none;
}
.ew-multi-column-form > .ew-multi-column-row .card-body .card-subtitle {
  color: #6C757D;
}
.ew-multi-column-form > .ew-multi-column-row .card-body .card-title, .ew-multi-column-form > .ew-multi-column-row .card-body .card-subtitle {
  margin-bottom: 0.5rem;
  font-weight: 500;
}
.ew-multi-column-form > .ew-multi-column-row .card-body .card-text {
  margin-bottom: 1rem;
}
.ew-multi-column-form > .ew-multi-column-row .card .ew-images > *:not(:first-child) {
  display: none;
}
.ew-multi-column-form .ew-multi-column-list-option .ew-list-option-body[data-name=checkbox] label {
  margin-top: 0;
  margin-bottom: 0;
}

.ew-multi-column-list-option {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  display: flex;
}
.ew-multi-column-list-option.ew-top-end, .ew-multi-column-list-option.ew-bottom-end {
  flex-direction: row-reverse;
}
.ew-multi-column-list-option .ew-list-option-body {
  float: left;
}
.ew-multi-column-list-option .dropdown-toggle::after {
  display: none;
}
.ew-multi-column-list-option [data-name=checkbox] .form-check {
  margin: 0.25rem;
}

.ew-layout-option .btn-group .form-check {
  margin: 0.25rem;
}

.ew-list-option-header label.checkbox, .table th label.checkbox {
  margin-bottom: 0;
}

.ew-list-option-body {
  vertical-align: middle !important;
}
.ew-list-option-body .btn-group > .btn {
  float: none;
}
.ew-list-option-body .ew-row-link, .ew-list-option-body .ew-row-link:hover, .ew-list-option-body .ew-row-link:focus {
  text-decoration: none;
}
.ew-list-option-body:not(td) {
  padding-right: 0.5rem;
}

.ew-row-link + .ew-row-link {
  padding-left: 0.5rem;
}

.ew-view-table {
  min-width: 300px;
}
.ew-view-table > tbody > tr > td:first-child {
  font-weight: bold;
}

body:not(.dark-mode) .ew-view-table, body:not(.dark-mode) .ew-desktop-table {
  background-color: #FFFFFF;
}

.ew-preview-upper-panel, .ew-preview-lower-panel {
  display: flex;
}
.ew-preview-upper-panel .ew-detail-count, .ew-preview-upper-panel .ew-preview-other-options, .ew-preview-lower-panel .ew-detail-count, .ew-preview-lower-panel .ew-preview-other-options {
  float: left;
}
.ew-preview-upper-panel .ew-detail-count, .ew-preview-lower-panel .ew-detail-count {
  margin-bottom: 1rem;
  padding-right: 10px;
  /*rtl:ignore*/
  direction: ltr;
}

.icon-expand, .icon-collapse, .ew-group-toggle {
  cursor: pointer;
}

.break-before-page {
  break-before: page;
}

.break-before-avoid {
  break-before: avoid;
}

.break-after-page {
  break-after: page;
}

.break-after-avoid {
  break-after: avoid;
}

.ew-export-table {
  border: 0;
  border-collapse: collapse;
}
.ew-export-table td {
  border: 1px solid;
  border-color: var(--bs-border-color);
}
.ew-export-table .ew-table-header, .ew-export-table .ew-rpt-col-summary, .ew-export-table .ew-rpt-col-header, .ew-export-table .ew-export-table-header td {
  background-color: #5797BC;
  color: #000000;
}
.ew-export-table .ew-export-table-row > td {
  background-color: transparent;
  color: #000000;
}
.ew-export-table .ew-export-table-alt-row > td {
  background-color: rgba(0, 0, 0, 0.05);
  color: #000000;
}
.ew-export-table .ew-export-table-footer {
  background-color: #E7F1F7;
  color: #000000;
}

.ew-list-export-options, .ew-view-export-options, .ew-view-other-options {
  margin-bottom: 1rem;
}

.ew-extended-search [class^=col-], .ew-extended-search [class*=" col-"] {
  padding-left: 0;
}

.ew-extended-search .row .date {
  white-space: normal;
}

.ew-search-cond .form-check + .form-check {
  margin-left: 0.5rem;
}

.ew-search-caption, .ew-search-operator, .ew-search-cond, .ew-search-and, .ew-search-operator2,
.ew-search-field, .ew-search-field2 {
  display: inline-flex;
  margin-right: 0.5rem;
}

.ew-search-field-single {
  display: inline-flex;
}

.modal .ew-search-cond, .modal .ew-search-and {
  display: inline-flex;
  height: calc(1.5em + 0.75rem + 2px);
}
.modal .ew-search-operator select {
  margin-bottom: 0.5rem;
}
.modal .ew-search-operator2 select {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.modal .ew-search-cond .form-check, .modal .ew-search-and label {
  margin-top: 0.5rem;
}

.ew-search-and label {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
}

.ew-search-operator, .ew-search-operator2 {
  color: #6C757D;
  font-weight: normal;
}

#ew-email-form #subject, #ew-email-form #message {
  width: 100%;
}

#ew-email-form #message {
  max-width: 100%;
}

.ew-rpt-grp-header {
  border-bottom: 1px solid;
  border-right: 1px solid;
  font-weight: bold;
}
.ew-rpt-grp-header a:link, .ew-rpt-grp-header a:active, .ew-rpt-grp-header a:visited {
  color: inherit;
  text-decoration: none;
}

body:not(.dark-mode) .ew-rpt-grp-header {
  border-color: #508BAD;
}

.ew-rpt-grp-hide-1 {
  display: none !important;
}

body:not(.dark-mode) .ew-rpt-grp-field-1 {
  --bs-table-accent-bg: #E7F1F7;
  color: var(--bs-table-color);
}
body:not(.dark-mode) .ew-rpt-grp-summary-1 {
  --bs-table-accent-bg: #E7F1F7;
  color: var(--bs-table-color);
}

.ew-rpt-grp-hide-2 {
  display: none !important;
}

body:not(.dark-mode) .ew-rpt-grp-field-2 {
  --bs-table-accent-bg: var(--bs-table-bg);
  color: var(--bs-table-color);
}
body:not(.dark-mode) .ew-rpt-grp-summary-2 {
  --bs-table-accent-bg: var(--bs-table-bg);
  color: var(--bs-table-color);
}

.ew-rpt-grp-hide-3 {
  display: none !important;
}

body:not(.dark-mode) .ew-rpt-grp-field-3 {
  --bs-table-accent-bg: #E7F1F7;
  color: var(--bs-table-color);
}
body:not(.dark-mode) .ew-rpt-grp-summary-3 {
  --bs-table-accent-bg: #E7F1F7;
  color: var(--bs-table-color);
}

.ew-rpt-grp-hide-4 {
  display: none !important;
}

body:not(.dark-mode) .ew-rpt-grp-field-4 {
  --bs-table-accent-bg: var(--bs-table-bg);
  color: var(--bs-table-color);
}
body:not(.dark-mode) .ew-rpt-grp-summary-4 {
  --bs-table-accent-bg: var(--bs-table-bg);
  color: var(--bs-table-color);
}

.ew-rpt-grp-hide-5 {
  display: none !important;
}

body:not(.dark-mode) .ew-rpt-grp-field-5 {
  --bs-table-accent-bg: #E7F1F7;
  color: var(--bs-table-color);
}
body:not(.dark-mode) .ew-rpt-grp-summary-5 {
  --bs-table-accent-bg: #E7F1F7;
  color: var(--bs-table-color);
}

.ew-rpt-grp-hide-6 {
  display: none !important;
}

body:not(.dark-mode) .ew-rpt-grp-field-6 {
  --bs-table-accent-bg: var(--bs-table-bg);
  color: var(--bs-table-color);
}
body:not(.dark-mode) .ew-rpt-grp-summary-6 {
  --bs-table-accent-bg: var(--bs-table-bg);
  color: var(--bs-table-color);
}

tr[data-group] .ew-rpt-grp-caret {
  border-right: 0 !important;
}
tr[data-group] td[class^=ew-rpt-grp-field-]:empty {
  border-bottom: 0 !important;
}
tr[data-group] .ew-group-toggle {
  transition: transform 0.3s linear;
}
tr[data-group] .ew-group-toggle.ew-rpt-grp-hide {
  transform: rotate(-90deg);
}
tr[data-group] .ew-group-toggle :not(.ew-rpt-grp-hide) {
  transform: rotate(90deg);
}

.ew-rpt-grp-aggregate {
  font-weight: bold;
}

.ew-rpt-page-summary {
  --bs-table-accent-bg: #E3EFF6;
  color: var(--bs-table-color);
}

.ew-rpt-grand-summary {
  --bs-table-accent-bg: #DFEDF5;
  color: var(--bs-table-color);
}

.ew-crosstab-values {
  margin-bottom: 0;
}

.ew-filter-caption::after {
  content: ": ";
}

.ew-drill-link:hover {
  text-decoration: none;
}

#ew-drilldown-panel .popover {
  font-size: 0.875rem;
  max-width: 1140px;
}
#ew-drilldown-panel .popover.bottom {
  margin-top: 0.25rem;
}
#ew-drilldown-panel .popover .popover-body {
  max-height: 800px;
  overflow-y: auto;
}

.ew-detail-count {
  display: none;
}

.ew-summary-value {
  display: none;
}

.ew-message-dialog {
  display: table;
  min-width: 300px;
}

.ew-required {
  color: #DC3545;
  margin-left: 0.5em;
  font-size: 50%;
  vertical-align: super;
}

.ew-template {
  display: none;
}

.ew-item-container .d-sm-table-cell {
  padding: 0.125rem 0.25rem;
  white-space: nowrap;
}
.ew-item-container .d-sm-table-cell .form-check-label {
  justify-content: left;
}

.ew-upload-table {
  margin-bottom: 0;
}
.ew-upload-table.ew-has-rows {
  margin-top: 0.75rem;
}
.ew-upload-table .progress {
  min-width: 75px;
}
.ew-upload-table tbody.files tr:first-child td {
  border-top: 0;
}
.ew-upload-table tbody.files tr:last-child td {
  border-bottom: 0;
}

input.cke_dialog_ui_input_text {
  min-height: 1.5rem;
}

li.ui-timepicker-selected,
.ui-timepicker-list li:hover,
.ui-timepicker-list .ui-timepicker-selected:hover {
  background: #0D6EFD;
}

.tempus-dominus-widget {
  z-index: 1070;
  color: #212529;
}

.ew-map label {
  width: auto;
  display: inline;
}

.nav-tabs, .nav-pills {
  margin-bottom: 1rem;
}

.ew-auto-suggest .twitter-typeahead input[name^=sv_] {
  vertical-align: baseline !important;
}
.ew-auto-suggest .tt-hint {
  color: #6C757D;
}
.ew-auto-suggest .tt-suggestion {
  cursor: pointer;
}
.ew-auto-suggest .tt-dataset {
  overflow-y: auto;
}
.ew-auto-suggest .tt-footer {
  pointer-events: none;
}
.ew-auto-suggest .tt-menu {
  z-index: 1081 !important;
}

.modal-body .ew-auto-suggest .tt-menu {
  z-index: 1081 !important;
}

.ew-detail-pages {
  margin-bottom: 1rem;
}

.ew-custom-template, .ew-custom-template-page, .ew-custom-template-search {
  margin-bottom: 1rem;
}

.ew-grid .ew-custom-template {
  margin-bottom: 0;
}

.ew-debug {
  margin-bottom: 1rem;
}

.ew-chart-top ~ .ew-debug, .ew-chart-bottom ~ .ew-debug {
  margin-top: 1rem;
}

.dropdown-submenu > a::after {
  position: absolute;
  right: 0.5em;
  top: calc((1.3125rem + 0.25rem * 2 - 0.3em) / 2);
}

.dropdown-submenu.float-start {
  float: none;
}
.dropdown-submenu.float-start > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  border-radius: 0.375rem 0 0.375rem 0.375rem;
}

/* desktop */
@media (min-width: 576px) {
  .w-col-1 {
    width: 8.33333% !important;
  }
  .w-col-2 {
    width: 16.66667% !important;
  }
  .w-col-3 {
    width: 25% !important;
  }
  .w-col-4 {
    width: 33.33333% !important;
  }
  .w-col-5 {
    width: 41.66667% !important;
  }
  .w-col-6 {
    width: 50% !important;
  }
  .w-col-7 {
    width: 58.33333% !important;
  }
  .w-col-8 {
    width: 66.66667% !important;
  }
  .w-col-9 {
    width: 75% !important;
  }
  .w-col-10 {
    width: 83.33333% !important;
  }
  .w-col-11 {
    width: 91.66667% !important;
  }
  .w-col-12 {
    width: 100% !important;
  }
  .ew-grid {
    min-width: 550px;
  }
  .form-control:not(.form-control-color) {
    display: inline;
    max-width: none;
  }
  .form-control:not(.form-control-color):not(textarea), .form-select {
    width: auto;
  }
  .form-range {
    width: 18rem;
  }
  .input-group > .form-control:not(.form-control-navbar),
  .input-group > .form-select {
    width: auto;
    flex: initial;
  }
  [id^=fd_] > .input-group {
    width: 50%;
  }
  .ew-grid-add-edit .input-group {
    flex-wrap: nowrap;
  }
  .ew-grid-add-edit [id^=fd_] > .input-group {
    width: 100%;
    min-width: 200px;
  }
  .ew-search-operator .form-control-plaintext {
    width: auto;
  }
  input[type=text]:not([size]):not(.ew-page-number):not(.cke_dialog_ui_input_text):not(.form-control-plaintext),
  input[type=password]:not([size]) {
    min-width: 250px;
  }
  .ew-custom-select, .ew-custom-file {
    max-width: 20em;
  }
  .ew-auto-suggest {
    display: inline-block;
  }
  .ew-upload-table {
    width: auto;
  }
  .ew-editor, .mce-tinymce {
    width: auto !important;
  }
  .modal-body .ew-editor, .modal-body .mce-tinymce {
    width: auto !important;
  }
}
@media (min-width: 576px) and (min-width: 768px) {
  .ew-form:not(.ew-list-form):not(.ew-user-priv-form),
  table.ew-master-table.ew-vertical,
  .query-builder {
    max-width: 800px;
  }
}
@media (min-width: 576px) and (min-width: 992px) {
  .ew-form:not(.ew-list-form):not(.ew-user-priv-form),
  table.ew-master-table.ew-vertical,
  .query-builder {
    max-width: 1140px;
  }
}
@media (min-width: 576px) {
  .ew-detail-pages {
    display: table;
  }
  .modal-dialog .ew-form {
    width: 100% !important;
  }
  .ew-login-box, .ew-reset-pwd-box, .ew-change-pwd-box {
    width: 360px;
  }
  .modal-md {
    max-width: 500px;
  }
  .select2 {
    width: auto !important;
    display: inline-block;
  }
  .input-group .select2-container--bootstrap5 {
    flex-grow: 0;
  }
  .ew-file-drop-zone {
    width: 30rem;
  }
}
/* mobile */
@media (max-width: 575px) {
  .ew-search-cond, .ew-search-and {
    display: inline-flex;
    height: calc(1.5em + 0.75rem + 2px);
  }
  .ew-search-operator select {
    margin-bottom: 0.5rem;
  }
  .ew-search-operator2 select {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .ew-search-cond .form-check, .ew-search-and label {
    margin-top: 0.5rem;
  }
  .ew-search-field, .ew-search-field2 {
    display: block;
  }
  .ew-grid {
    display: block;
  }
  .ew-grid .ew-grid-middle-panel {
    overflow-x: auto;
    overflow-y: visible;
  }
  .ew-multicolumn-form > .ew-multi-column-row > div[class^=col-]:not(:last-child) {
    margin-bottom: 15px;
  }
  .ew-auto-suggest {
    display: block;
  }
  #ew-map {
    width: 100% !important;
  }
  .ew-desktop {
    display: block;
  }
  .ew-desktop .ew-desktop-table {
    border: 0;
  }
  .ew-desktop .ew-desktop-table > tbody > tr > td {
    border: 0;
    display: inline-block;
  }
  .ew-desktop .ew-desktop-table > tbody > tr > td:first-of-type {
    text-align: inherit;
    padding-right: 0.75rem;
  }
  .ew-desktop .ew-desktop-table > tbody > tr > td:last-of-type {
    display: block;
  }
  .table-striped > tbody > tr:nth-child(odd),
  .table-striped > tbody > tr:nth-child(even),
  .table-striped > tbody > tr:nth-child(odd) > td,
  .table-striped > tbody > tr:nth-child(even) > td {
    background-color: transparent;
  }
  .ew-login-box .ew-recaptcha-normal, .ew-reset-pwd-box .ew-recaptcha-normal, .ew-change-pwd-box .ew-recaptcha-normal {
    margin-left: calc((100% - 300px) / 2);
  }
  .select2 {
    width: 100%;
  }
  .select2-container--bootstrap5 .ew-select-dropdown.ew-select-multiple div.select2-results__option[role=option] {
    border-radius: 0 !important;
  }
}
.ew-login-box, .ew-reset-pwd-box, .ew-change-pwd-box {
  margin-left: auto;
  margin-right: auto;
}
.ew-login-box .ew-form-control, .ew-reset-pwd-box .ew-form-control, .ew-change-pwd-box .ew-form-control {
  width: 100% !important;
}
.ew-login-box .input-group, .ew-reset-pwd-box .input-group, .ew-change-pwd-box .input-group {
  flex-wrap: nowrap;
}
.ew-login-box .input-group > .form-control, .ew-reset-pwd-box .input-group > .form-control, .ew-change-pwd-box .input-group > .form-control {
  flex-grow: 1;
}
.ew-login-box #password, .ew-reset-pwd-box #password, .ew-change-pwd-box #password {
  width: calc(100% - 0.875rem * 1.25 - 0.75rem * 2 - 1px);
}

.ew-change-pwd-box #opwd, .ew-change-pwd-box #npwd, .ew-change-pwd-box #cpwd {
  width: calc(100% - 0.875rem * 1.25 - 0.75rem * 2 - 1px);
}
.ew-change-pwd-box #npwd.ew-password-strength {
  width: calc(100% - 0.875rem * 1.25 * 2 - 0.75rem * 4 - 1px * 2);
}
.ew-change-pwd-box #pst_npwd {
  margin-top: 5px;
  margin-bottom: 0px;
}

.ew-login-form, .ew-forgot-pwd-form, .ew-change-pwd-form {
  width: 100% !important;
}

.modal-body .ew-login-box .card, .modal-body .ew-reset-pwd-box .card, .modal-body .ew-change-pwd-box .card {
  border: 0;
}

#cboxOverlay {
  background: #000 none;
}

.ew-home, .ew-user-permission {
  display: inline-block;
}

.breadcrumb-item .ew-icon {
  margin-top: 0.4rem;
}

.ew-hidden {
  display: none !important;
}

.ew-disabled {
  cursor: not-allowed !important;
  pointer-events: none !important;
}
.ew-disabled.btn {
  opacity: 0.65 !important;
}

.ew-option:not(:last-of-type)::after {
  content: ", ";
}

.is-invalid .tt-input {
  border-color: #DC3545;
}
.is-invalid .tt-input:focus {
  border-color: #DC3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.input-group .tt-input {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.tabulator {
  font-size: 0.875rem !important;
}
.tabulator .tabulator-header .tabulator-col .tabulator-col-content {
  padding: 0 !important;
}
.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-col-title {
  padding: 0.25rem 0.75rem !important;
}
.tabulator .tabulator-row .tabulator-cell {
  padding: 0.25rem 0.75rem !important;
}

#ew-import-dialog .tabulator {
  font-size: 0.765625rem !important;
}
#ew-import-dialog .tooltip-inner {
  max-width: 500px;
  background-color: #DC3545;
}
#ew-import-dialog .tooltip-arrow::before {
  border-bottom-color: #DC3545;
}
#ew-import-dialog .progress-bar {
  transition-duration: 0.4s;
}

#cookie-consent {
  z-index: 1034;
  padding: 0;
  border-radius: 0;
}
@media (min-width: 768px) {
  #cookie-consent {
    transition: margin-left 0.3s ease-in-out;
    margin-left: 250px;
  }
}
@media (min-width: 768px) and (prefers-reduced-motion: reduce) {
  #cookie-consent {
    transition: none;
  }
}
@media (min-width: 768px) {
  .layout-top-nav #cookie-consent {
    margin-left: 0;
  }
}
@media (max-width: 767.98px) {
  #cookie-consent, #cookie-consent:before {
    margin-left: 0;
  }
}

#cookie-consent > .alert.alert-dismissible {
  border-radius: 0;
  margin-bottom: 0;
}

.navbar.bg-primary .nav-item.dropdown .btn-default {
  color: #444 !important;
}

.navbar.bg-secondary .nav-item.dropdown .btn-default {
  color: #444 !important;
}

.navbar.bg-success .nav-item.dropdown .btn-default {
  color: #444 !important;
}

.navbar.bg-info .nav-item.dropdown .btn-default {
  color: #444 !important;
}

.navbar.bg-warning .nav-item.dropdown .btn-default {
  color: #444 !important;
}

.navbar.bg-danger .nav-item.dropdown .btn-default {
  color: #444 !important;
}

.navbar.bg-light .nav-item.dropdown .btn-default {
  color: #444 !important;
}

.navbar.bg-dark .nav-item.dropdown .btn-default {
  color: #444 !important;
}

body > .dropdown-menu {
  z-index: 1071;
}

.dropdown-menu-lg {
  max-width: none;
}

.dropdown-menu .dropdown-divider + .dropdown-divider,
.dropdown-menu .dropdown-divider + .d-none + .dropdown-divider,
.dropdown-menu .dropdown-divider + .d-none + .d-none + .dropdown-divider,
.dropdown-menu .dropdown-divider + .d-none + .d-none + .d-none + .dropdown-divider,
.dropdown-menu .dropdown-divider + .d-none + .d-none + .d-none + .d-none + .dropdown-divider,
.dropdown-menu .dropdown-divider:only-child {
  display: none;
}

.navbar-badge {
  font-size: 0.5rem;
}

.ew-accordion {
  margin-bottom: 1rem;
}

.sidebar, .control-sidebar-content {
  overflow: hidden;
}

.input-group[data-widget=sidebar-search] {
  flex-wrap: nowrap;
}

.ew-wait {
  cursor: wait;
}
.ew-wait input,
.ew-wait select,
.ew-wait .input-group button,
.ew-wait .btn-group button,
.ew-wait .select2-selection {
  cursor: wait;
}

.toast-container {
  z-index: 1060;
}

.ew-toast {
  min-width: 350px;
  z-index: 1070;
}
.ew-toast.bg-primary .btn-close {
  filter: invert(1) grayscale(100%) brightness(200%);
}
.ew-toast.bg-secondary .btn-close {
  filter: invert(1) grayscale(100%) brightness(200%);
}
.ew-toast.bg-success .btn-close {
  filter: invert(1) grayscale(100%) brightness(200%);
}
.ew-toast.bg-danger .btn-close {
  filter: invert(1) grayscale(100%) brightness(200%);
}
.ew-toast.bg-dark .btn-close {
  filter: invert(1) grayscale(100%) brightness(200%);
}

html[dir=rtl] .ew-prev-next .ew-icon {
  transform: rotate(180deg);
}
html[dir=rtl] .ew-summary-count,
html[dir=rtl] .ew-detail-count,
html[dir=rtl] .ew-crosstab-values {
  /*rtl:ignore*/
  direction: ltr;
  unicode-bidi: bidi-override;
}

@media (min-width: 768px) {
  body.ew-layout-top-nav .content-wrapper,
  body.ew-layout-top-nav .main-footer,
  body.ew-layout-top-nav .main-header {
    margin-left: 0 !important;
  }
}
@media (min-width: 768px) {
  body.ew-layout-top-nav .main-sidebar {
    margin-left: -250px;
  }
}

.table.ew-table.table-head-fixed thead tr:nth-child(1) th {
  background-color: #5797BC;
  box-shadow: none;
  outline: 1px solid #508BAD;
  outline-offset: -1px;
}
.table.ew-table.table-head-fixed.table-dark thead tr:nth-child(1) th {
  background-color: #5797BC;
  box-shadow: none;
  outline: 1px solid #508BAD;
  outline-offset: -1px;
}

.overflow-y-auto > .table.ew-table.table-head-fixed {
  position: relative;
}

.error-page {
  margin: 20px 0 0;
  width: auto;
}
.error-page > .headline {
  float: none;
  font-size: 75px;
}
.error-page > .error-content {
  margin-left: 0;
}
.error-page > .error-content > h3 {
  text-align: inherit;
}

.swal2-input.ew-swal2-input {
  font-size: 0.875rem;
}
.swal2-input.ew-swal2-input:focus {
  color: #212529;
  background-color: #FFFFFF;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.swal2-input.ew-swal2-input.swal2-inputerror:focus {
  border-color: #DC3545 !important;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25) !important;
}

.ew-swal2-validation-message {
  border: 0 !important;
  border-radius: 0 !important;
}

.ew-swal2-actions .btn {
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .ew-swal2-actions .btn {
    transition: none;
  }
}
.ew-swal2-actions .btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check:focus + .ew-swal2-actions .btn, .ew-swal2-actions .btn:focus {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .ew-swal2-actions .btn, .btn-check:active + .ew-swal2-actions .btn, .ew-swal2-actions .btn:active, .ew-swal2-actions .btn.active, .ew-swal2-actions .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .ew-swal2-actions .btn:focus, .btn-check:active + .ew-swal2-actions .btn:focus, .ew-swal2-actions .btn:active:focus, .ew-swal2-actions .btn.active:focus, .ew-swal2-actions .btn.show:focus {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.ew-swal2-actions .btn:disabled, .ew-swal2-actions .btn.disabled, fieldset:disabled .ew-swal2-actions .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.ew-swal2-title {
  font-size: 1.3125rem;
}

.swal2-html-container {
  font-size: 0.875rem;
}

.btn-outline-lightblue {
  --bs-btn-color: #63a4c9;
  --bs-btn-border-color: #63a4c9;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #63a4c9;
  --bs-btn-hover-border-color: #63a4c9;
  --bs-btn-focus-shadow-rgb: 99, 164, 201;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #63a4c9;
  --bs-btn-active-border-color: #63a4c9;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #63a4c9;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #63a4c9;
  --bs-gradient: none;
}

.btn-outline-navy {
  --bs-btn-color: #0d2a49;
  --bs-btn-border-color: #0d2a49;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #0d2a49;
  --bs-btn-hover-border-color: #0d2a49;
  --bs-btn-focus-shadow-rgb: 13, 42, 73;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #0d2a49;
  --bs-btn-active-border-color: #0d2a49;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0d2a49;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0d2a49;
  --bs-gradient: none;
}

.btn-outline-olive {
  --bs-btn-color: #64ad8d;
  --bs-btn-border-color: #64ad8d;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #64ad8d;
  --bs-btn-hover-border-color: #64ad8d;
  --bs-btn-focus-shadow-rgb: 100, 173, 141;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #64ad8d;
  --bs-btn-active-border-color: #64ad8d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #64ad8d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #64ad8d;
  --bs-gradient: none;
}

.btn-outline-lime {
  --bs-btn-color: #34ff8d;
  --bs-btn-border-color: #34ff8d;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #34ff8d;
  --bs-btn-hover-border-color: #34ff8d;
  --bs-btn-focus-shadow-rgb: 52, 255, 141;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #34ff8d;
  --bs-btn-active-border-color: #34ff8d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #34ff8d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #34ff8d;
  --bs-gradient: none;
}

.btn-outline-fuchsia {
  --bs-btn-color: #f341cb;
  --bs-btn-border-color: #f341cb;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #f341cb;
  --bs-btn-hover-border-color: #f341cb;
  --bs-btn-focus-shadow-rgb: 243, 65, 203;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #f341cb;
  --bs-btn-active-border-color: #f341cb;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f341cb;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f341cb;
  --bs-gradient: none;
}

.btn-outline-maroon {
  --bs-btn-color: #e04980;
  --bs-btn-border-color: #e04980;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #e04980;
  --bs-btn-hover-border-color: #e04980;
  --bs-btn-focus-shadow-rgb: 224, 73, 128;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #e04980;
  --bs-btn-active-border-color: #e04980;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #e04980;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #e04980;
  --bs-gradient: none;
}

.border-lightblue {
  border-color: #63a4c9;
}

.popover-lightblue {
  --bs-popover-border-color: #63a4c9;
  --bs-popover-arrow-border: #63a4c9;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
}
.popover-lightblue.bs-popover-start .popover-arrow::before, .popover-lightblue.bs-popover-auto[data-popper-placement^=start] .popover-arrow::before, .popover-lightblue.bs-popover-end .popover-arrow::before, .popover-lightblue.bs-popover-auto[data-popper-placement^=end] .popover-arrow::before {
  border-left-color: var(--bs-popover-arrow-border);
  border-right-color: var(--bs-popover-arrow-border);
}
.popover-lightblue.bs-popover-top .popover-arrow::before, .popover-lightblue.bs-popover-auto[data-popper-placement^=top] .popover-arrow::before {
  border-top-color: var(--bs-popover-arrow-border);
}
.popover-lightblue.bs-popover-bottom .popover-arrow::before, .popover-lightblue.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-lightblue.bs-popover-bottom .popover-arrow::after, .popover-lightblue.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::after {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-lightblue.bs-popover-bottom .popover-header::before, .popover-lightblue.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-lightblue .popover-header {
  --bs-popover-header-bg: #63a4c9;
  border-bottom-color: var(--bs-popover-header-bg);
  --bs-popover-header-color: #000000;
}

.border-navy {
  border-color: #0d2a49;
}

.popover-navy {
  --bs-popover-border-color: #0d2a49;
  --bs-popover-arrow-border: #0d2a49;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
}
.popover-navy.bs-popover-start .popover-arrow::before, .popover-navy.bs-popover-auto[data-popper-placement^=start] .popover-arrow::before, .popover-navy.bs-popover-end .popover-arrow::before, .popover-navy.bs-popover-auto[data-popper-placement^=end] .popover-arrow::before {
  border-left-color: var(--bs-popover-arrow-border);
  border-right-color: var(--bs-popover-arrow-border);
}
.popover-navy.bs-popover-top .popover-arrow::before, .popover-navy.bs-popover-auto[data-popper-placement^=top] .popover-arrow::before {
  border-top-color: var(--bs-popover-arrow-border);
}
.popover-navy.bs-popover-bottom .popover-arrow::before, .popover-navy.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-navy.bs-popover-bottom .popover-arrow::after, .popover-navy.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::after {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-navy.bs-popover-bottom .popover-header::before, .popover-navy.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-navy .popover-header {
  --bs-popover-header-bg: #0d2a49;
  border-bottom-color: var(--bs-popover-header-bg);
  --bs-popover-header-color: #FFFFFF;
}

.border-olive {
  border-color: #64ad8d;
}

.popover-olive {
  --bs-popover-border-color: #64ad8d;
  --bs-popover-arrow-border: #64ad8d;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
}
.popover-olive.bs-popover-start .popover-arrow::before, .popover-olive.bs-popover-auto[data-popper-placement^=start] .popover-arrow::before, .popover-olive.bs-popover-end .popover-arrow::before, .popover-olive.bs-popover-auto[data-popper-placement^=end] .popover-arrow::before {
  border-left-color: var(--bs-popover-arrow-border);
  border-right-color: var(--bs-popover-arrow-border);
}
.popover-olive.bs-popover-top .popover-arrow::before, .popover-olive.bs-popover-auto[data-popper-placement^=top] .popover-arrow::before {
  border-top-color: var(--bs-popover-arrow-border);
}
.popover-olive.bs-popover-bottom .popover-arrow::before, .popover-olive.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-olive.bs-popover-bottom .popover-arrow::after, .popover-olive.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::after {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-olive.bs-popover-bottom .popover-header::before, .popover-olive.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-olive .popover-header {
  --bs-popover-header-bg: #64ad8d;
  border-bottom-color: var(--bs-popover-header-bg);
  --bs-popover-header-color: #000000;
}

.border-lime {
  border-color: #34ff8d;
}

.popover-lime {
  --bs-popover-border-color: #34ff8d;
  --bs-popover-arrow-border: #34ff8d;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
}
.popover-lime.bs-popover-start .popover-arrow::before, .popover-lime.bs-popover-auto[data-popper-placement^=start] .popover-arrow::before, .popover-lime.bs-popover-end .popover-arrow::before, .popover-lime.bs-popover-auto[data-popper-placement^=end] .popover-arrow::before {
  border-left-color: var(--bs-popover-arrow-border);
  border-right-color: var(--bs-popover-arrow-border);
}
.popover-lime.bs-popover-top .popover-arrow::before, .popover-lime.bs-popover-auto[data-popper-placement^=top] .popover-arrow::before {
  border-top-color: var(--bs-popover-arrow-border);
}
.popover-lime.bs-popover-bottom .popover-arrow::before, .popover-lime.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-lime.bs-popover-bottom .popover-arrow::after, .popover-lime.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::after {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-lime.bs-popover-bottom .popover-header::before, .popover-lime.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-lime .popover-header {
  --bs-popover-header-bg: #34ff8d;
  border-bottom-color: var(--bs-popover-header-bg);
  --bs-popover-header-color: #000000;
}

.border-fuchsia {
  border-color: #f341cb;
}

.popover-fuchsia {
  --bs-popover-border-color: #f341cb;
  --bs-popover-arrow-border: #f341cb;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
}
.popover-fuchsia.bs-popover-start .popover-arrow::before, .popover-fuchsia.bs-popover-auto[data-popper-placement^=start] .popover-arrow::before, .popover-fuchsia.bs-popover-end .popover-arrow::before, .popover-fuchsia.bs-popover-auto[data-popper-placement^=end] .popover-arrow::before {
  border-left-color: var(--bs-popover-arrow-border);
  border-right-color: var(--bs-popover-arrow-border);
}
.popover-fuchsia.bs-popover-top .popover-arrow::before, .popover-fuchsia.bs-popover-auto[data-popper-placement^=top] .popover-arrow::before {
  border-top-color: var(--bs-popover-arrow-border);
}
.popover-fuchsia.bs-popover-bottom .popover-arrow::before, .popover-fuchsia.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-fuchsia.bs-popover-bottom .popover-arrow::after, .popover-fuchsia.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::after {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-fuchsia.bs-popover-bottom .popover-header::before, .popover-fuchsia.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-fuchsia .popover-header {
  --bs-popover-header-bg: #f341cb;
  border-bottom-color: var(--bs-popover-header-bg);
  --bs-popover-header-color: #000000;
}

.border-maroon {
  border-color: #e04980;
}

.popover-maroon {
  --bs-popover-border-color: #e04980;
  --bs-popover-arrow-border: #e04980;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
}
.popover-maroon.bs-popover-start .popover-arrow::before, .popover-maroon.bs-popover-auto[data-popper-placement^=start] .popover-arrow::before, .popover-maroon.bs-popover-end .popover-arrow::before, .popover-maroon.bs-popover-auto[data-popper-placement^=end] .popover-arrow::before {
  border-left-color: var(--bs-popover-arrow-border);
  border-right-color: var(--bs-popover-arrow-border);
}
.popover-maroon.bs-popover-top .popover-arrow::before, .popover-maroon.bs-popover-auto[data-popper-placement^=top] .popover-arrow::before {
  border-top-color: var(--bs-popover-arrow-border);
}
.popover-maroon.bs-popover-bottom .popover-arrow::before, .popover-maroon.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-maroon.bs-popover-bottom .popover-arrow::after, .popover-maroon.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::after {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-maroon.bs-popover-bottom .popover-header::before, .popover-maroon.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-maroon .popover-header {
  --bs-popover-header-bg: #e04980;
  border-bottom-color: var(--bs-popover-header-bg);
  --bs-popover-header-color: #000000;
}

.border-blue {
  border-color: #0D6EFD;
}

.popover-blue {
  --bs-popover-border-color: #0D6EFD;
  --bs-popover-arrow-border: #0D6EFD;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
}
.popover-blue.bs-popover-start .popover-arrow::before, .popover-blue.bs-popover-auto[data-popper-placement^=start] .popover-arrow::before, .popover-blue.bs-popover-end .popover-arrow::before, .popover-blue.bs-popover-auto[data-popper-placement^=end] .popover-arrow::before {
  border-left-color: var(--bs-popover-arrow-border);
  border-right-color: var(--bs-popover-arrow-border);
}
.popover-blue.bs-popover-top .popover-arrow::before, .popover-blue.bs-popover-auto[data-popper-placement^=top] .popover-arrow::before {
  border-top-color: var(--bs-popover-arrow-border);
}
.popover-blue.bs-popover-bottom .popover-arrow::before, .popover-blue.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-blue.bs-popover-bottom .popover-arrow::after, .popover-blue.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::after {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-blue.bs-popover-bottom .popover-header::before, .popover-blue.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-blue .popover-header {
  --bs-popover-header-bg: #0D6EFD;
  border-bottom-color: var(--bs-popover-header-bg);
  --bs-popover-header-color: #FFFFFF;
}

.border-indigo {
  border-color: #6610F2;
}

.popover-indigo {
  --bs-popover-border-color: #6610F2;
  --bs-popover-arrow-border: #6610F2;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
}
.popover-indigo.bs-popover-start .popover-arrow::before, .popover-indigo.bs-popover-auto[data-popper-placement^=start] .popover-arrow::before, .popover-indigo.bs-popover-end .popover-arrow::before, .popover-indigo.bs-popover-auto[data-popper-placement^=end] .popover-arrow::before {
  border-left-color: var(--bs-popover-arrow-border);
  border-right-color: var(--bs-popover-arrow-border);
}
.popover-indigo.bs-popover-top .popover-arrow::before, .popover-indigo.bs-popover-auto[data-popper-placement^=top] .popover-arrow::before {
  border-top-color: var(--bs-popover-arrow-border);
}
.popover-indigo.bs-popover-bottom .popover-arrow::before, .popover-indigo.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-indigo.bs-popover-bottom .popover-arrow::after, .popover-indigo.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::after {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-indigo.bs-popover-bottom .popover-header::before, .popover-indigo.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-indigo .popover-header {
  --bs-popover-header-bg: #6610F2;
  border-bottom-color: var(--bs-popover-header-bg);
  --bs-popover-header-color: #FFFFFF;
}

.border-purple {
  border-color: #6F42C1;
}

.popover-purple {
  --bs-popover-border-color: #6F42C1;
  --bs-popover-arrow-border: #6F42C1;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
}
.popover-purple.bs-popover-start .popover-arrow::before, .popover-purple.bs-popover-auto[data-popper-placement^=start] .popover-arrow::before, .popover-purple.bs-popover-end .popover-arrow::before, .popover-purple.bs-popover-auto[data-popper-placement^=end] .popover-arrow::before {
  border-left-color: var(--bs-popover-arrow-border);
  border-right-color: var(--bs-popover-arrow-border);
}
.popover-purple.bs-popover-top .popover-arrow::before, .popover-purple.bs-popover-auto[data-popper-placement^=top] .popover-arrow::before {
  border-top-color: var(--bs-popover-arrow-border);
}
.popover-purple.bs-popover-bottom .popover-arrow::before, .popover-purple.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-purple.bs-popover-bottom .popover-arrow::after, .popover-purple.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::after {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-purple.bs-popover-bottom .popover-header::before, .popover-purple.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-purple .popover-header {
  --bs-popover-header-bg: #6F42C1;
  border-bottom-color: var(--bs-popover-header-bg);
  --bs-popover-header-color: #FFFFFF;
}

.border-pink {
  border-color: #D63384;
}

.popover-pink {
  --bs-popover-border-color: #D63384;
  --bs-popover-arrow-border: #D63384;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
}
.popover-pink.bs-popover-start .popover-arrow::before, .popover-pink.bs-popover-auto[data-popper-placement^=start] .popover-arrow::before, .popover-pink.bs-popover-end .popover-arrow::before, .popover-pink.bs-popover-auto[data-popper-placement^=end] .popover-arrow::before {
  border-left-color: var(--bs-popover-arrow-border);
  border-right-color: var(--bs-popover-arrow-border);
}
.popover-pink.bs-popover-top .popover-arrow::before, .popover-pink.bs-popover-auto[data-popper-placement^=top] .popover-arrow::before {
  border-top-color: var(--bs-popover-arrow-border);
}
.popover-pink.bs-popover-bottom .popover-arrow::before, .popover-pink.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-pink.bs-popover-bottom .popover-arrow::after, .popover-pink.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::after {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-pink.bs-popover-bottom .popover-header::before, .popover-pink.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-pink .popover-header {
  --bs-popover-header-bg: #D63384;
  border-bottom-color: var(--bs-popover-header-bg);
  --bs-popover-header-color: #FFFFFF;
}

.border-red {
  border-color: #DC3545;
}

.popover-red {
  --bs-popover-border-color: #DC3545;
  --bs-popover-arrow-border: #DC3545;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
}
.popover-red.bs-popover-start .popover-arrow::before, .popover-red.bs-popover-auto[data-popper-placement^=start] .popover-arrow::before, .popover-red.bs-popover-end .popover-arrow::before, .popover-red.bs-popover-auto[data-popper-placement^=end] .popover-arrow::before {
  border-left-color: var(--bs-popover-arrow-border);
  border-right-color: var(--bs-popover-arrow-border);
}
.popover-red.bs-popover-top .popover-arrow::before, .popover-red.bs-popover-auto[data-popper-placement^=top] .popover-arrow::before {
  border-top-color: var(--bs-popover-arrow-border);
}
.popover-red.bs-popover-bottom .popover-arrow::before, .popover-red.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-red.bs-popover-bottom .popover-arrow::after, .popover-red.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::after {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-red.bs-popover-bottom .popover-header::before, .popover-red.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-red .popover-header {
  --bs-popover-header-bg: #DC3545;
  border-bottom-color: var(--bs-popover-header-bg);
  --bs-popover-header-color: #FFFFFF;
}

.border-orange {
  border-color: #FD7E14;
}

.popover-orange {
  --bs-popover-border-color: #FD7E14;
  --bs-popover-arrow-border: #FD7E14;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
}
.popover-orange.bs-popover-start .popover-arrow::before, .popover-orange.bs-popover-auto[data-popper-placement^=start] .popover-arrow::before, .popover-orange.bs-popover-end .popover-arrow::before, .popover-orange.bs-popover-auto[data-popper-placement^=end] .popover-arrow::before {
  border-left-color: var(--bs-popover-arrow-border);
  border-right-color: var(--bs-popover-arrow-border);
}
.popover-orange.bs-popover-top .popover-arrow::before, .popover-orange.bs-popover-auto[data-popper-placement^=top] .popover-arrow::before {
  border-top-color: var(--bs-popover-arrow-border);
}
.popover-orange.bs-popover-bottom .popover-arrow::before, .popover-orange.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-orange.bs-popover-bottom .popover-arrow::after, .popover-orange.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::after {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-orange.bs-popover-bottom .popover-header::before, .popover-orange.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-orange .popover-header {
  --bs-popover-header-bg: #FD7E14;
  border-bottom-color: var(--bs-popover-header-bg);
  --bs-popover-header-color: #000000;
}

.border-yellow {
  border-color: #FFC107;
}

.popover-yellow {
  --bs-popover-border-color: #FFC107;
  --bs-popover-arrow-border: #FFC107;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
}
.popover-yellow.bs-popover-start .popover-arrow::before, .popover-yellow.bs-popover-auto[data-popper-placement^=start] .popover-arrow::before, .popover-yellow.bs-popover-end .popover-arrow::before, .popover-yellow.bs-popover-auto[data-popper-placement^=end] .popover-arrow::before {
  border-left-color: var(--bs-popover-arrow-border);
  border-right-color: var(--bs-popover-arrow-border);
}
.popover-yellow.bs-popover-top .popover-arrow::before, .popover-yellow.bs-popover-auto[data-popper-placement^=top] .popover-arrow::before {
  border-top-color: var(--bs-popover-arrow-border);
}
.popover-yellow.bs-popover-bottom .popover-arrow::before, .popover-yellow.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-yellow.bs-popover-bottom .popover-arrow::after, .popover-yellow.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::after {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-yellow.bs-popover-bottom .popover-header::before, .popover-yellow.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-yellow .popover-header {
  --bs-popover-header-bg: #FFC107;
  border-bottom-color: var(--bs-popover-header-bg);
  --bs-popover-header-color: #000000;
}

.border-green {
  border-color: #198754;
}

.popover-green {
  --bs-popover-border-color: #198754;
  --bs-popover-arrow-border: #198754;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
}
.popover-green.bs-popover-start .popover-arrow::before, .popover-green.bs-popover-auto[data-popper-placement^=start] .popover-arrow::before, .popover-green.bs-popover-end .popover-arrow::before, .popover-green.bs-popover-auto[data-popper-placement^=end] .popover-arrow::before {
  border-left-color: var(--bs-popover-arrow-border);
  border-right-color: var(--bs-popover-arrow-border);
}
.popover-green.bs-popover-top .popover-arrow::before, .popover-green.bs-popover-auto[data-popper-placement^=top] .popover-arrow::before {
  border-top-color: var(--bs-popover-arrow-border);
}
.popover-green.bs-popover-bottom .popover-arrow::before, .popover-green.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-green.bs-popover-bottom .popover-arrow::after, .popover-green.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::after {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-green.bs-popover-bottom .popover-header::before, .popover-green.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-green .popover-header {
  --bs-popover-header-bg: #198754;
  border-bottom-color: var(--bs-popover-header-bg);
  --bs-popover-header-color: #FFFFFF;
}

.border-teal {
  border-color: #20C997;
}

.popover-teal {
  --bs-popover-border-color: #20C997;
  --bs-popover-arrow-border: #20C997;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
}
.popover-teal.bs-popover-start .popover-arrow::before, .popover-teal.bs-popover-auto[data-popper-placement^=start] .popover-arrow::before, .popover-teal.bs-popover-end .popover-arrow::before, .popover-teal.bs-popover-auto[data-popper-placement^=end] .popover-arrow::before {
  border-left-color: var(--bs-popover-arrow-border);
  border-right-color: var(--bs-popover-arrow-border);
}
.popover-teal.bs-popover-top .popover-arrow::before, .popover-teal.bs-popover-auto[data-popper-placement^=top] .popover-arrow::before {
  border-top-color: var(--bs-popover-arrow-border);
}
.popover-teal.bs-popover-bottom .popover-arrow::before, .popover-teal.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-teal.bs-popover-bottom .popover-arrow::after, .popover-teal.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::after {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-teal.bs-popover-bottom .popover-header::before, .popover-teal.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-teal .popover-header {
  --bs-popover-header-bg: #20C997;
  border-bottom-color: var(--bs-popover-header-bg);
  --bs-popover-header-color: #000000;
}

.border-cyan {
  border-color: #0DCAF0;
}

.popover-cyan {
  --bs-popover-border-color: #0DCAF0;
  --bs-popover-arrow-border: #0DCAF0;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
}
.popover-cyan.bs-popover-start .popover-arrow::before, .popover-cyan.bs-popover-auto[data-popper-placement^=start] .popover-arrow::before, .popover-cyan.bs-popover-end .popover-arrow::before, .popover-cyan.bs-popover-auto[data-popper-placement^=end] .popover-arrow::before {
  border-left-color: var(--bs-popover-arrow-border);
  border-right-color: var(--bs-popover-arrow-border);
}
.popover-cyan.bs-popover-top .popover-arrow::before, .popover-cyan.bs-popover-auto[data-popper-placement^=top] .popover-arrow::before {
  border-top-color: var(--bs-popover-arrow-border);
}
.popover-cyan.bs-popover-bottom .popover-arrow::before, .popover-cyan.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-cyan.bs-popover-bottom .popover-arrow::after, .popover-cyan.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::after {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-cyan.bs-popover-bottom .popover-header::before, .popover-cyan.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-cyan .popover-header {
  --bs-popover-header-bg: #0DCAF0;
  border-bottom-color: var(--bs-popover-header-bg);
  --bs-popover-header-color: #000000;
}

.border-black {
  border-color: #000000;
}

.popover-black {
  --bs-popover-border-color: #000000;
  --bs-popover-arrow-border: #000000;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
}
.popover-black.bs-popover-start .popover-arrow::before, .popover-black.bs-popover-auto[data-popper-placement^=start] .popover-arrow::before, .popover-black.bs-popover-end .popover-arrow::before, .popover-black.bs-popover-auto[data-popper-placement^=end] .popover-arrow::before {
  border-left-color: var(--bs-popover-arrow-border);
  border-right-color: var(--bs-popover-arrow-border);
}
.popover-black.bs-popover-top .popover-arrow::before, .popover-black.bs-popover-auto[data-popper-placement^=top] .popover-arrow::before {
  border-top-color: var(--bs-popover-arrow-border);
}
.popover-black.bs-popover-bottom .popover-arrow::before, .popover-black.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-black.bs-popover-bottom .popover-arrow::after, .popover-black.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::after {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-black.bs-popover-bottom .popover-header::before, .popover-black.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-black .popover-header {
  --bs-popover-header-bg: #000000;
  border-bottom-color: var(--bs-popover-header-bg);
  --bs-popover-header-color: #FFFFFF;
}

.border-white {
  border-color: #FFFFFF;
}

.popover-white {
  --bs-popover-border-color: #FFFFFF;
  --bs-popover-arrow-border: #FFFFFF;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
}
.popover-white.bs-popover-start .popover-arrow::before, .popover-white.bs-popover-auto[data-popper-placement^=start] .popover-arrow::before, .popover-white.bs-popover-end .popover-arrow::before, .popover-white.bs-popover-auto[data-popper-placement^=end] .popover-arrow::before {
  border-left-color: var(--bs-popover-arrow-border);
  border-right-color: var(--bs-popover-arrow-border);
}
.popover-white.bs-popover-top .popover-arrow::before, .popover-white.bs-popover-auto[data-popper-placement^=top] .popover-arrow::before {
  border-top-color: var(--bs-popover-arrow-border);
}
.popover-white.bs-popover-bottom .popover-arrow::before, .popover-white.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-white.bs-popover-bottom .popover-arrow::after, .popover-white.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::after {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-white.bs-popover-bottom .popover-header::before, .popover-white.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-white .popover-header {
  --bs-popover-header-bg: #FFFFFF;
  border-bottom-color: var(--bs-popover-header-bg);
  --bs-popover-header-color: #000000;
}

.border-gray {
  border-color: #6C757D;
}

.popover-gray {
  --bs-popover-border-color: #6C757D;
  --bs-popover-arrow-border: #6C757D;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
}
.popover-gray.bs-popover-start .popover-arrow::before, .popover-gray.bs-popover-auto[data-popper-placement^=start] .popover-arrow::before, .popover-gray.bs-popover-end .popover-arrow::before, .popover-gray.bs-popover-auto[data-popper-placement^=end] .popover-arrow::before {
  border-left-color: var(--bs-popover-arrow-border);
  border-right-color: var(--bs-popover-arrow-border);
}
.popover-gray.bs-popover-top .popover-arrow::before, .popover-gray.bs-popover-auto[data-popper-placement^=top] .popover-arrow::before {
  border-top-color: var(--bs-popover-arrow-border);
}
.popover-gray.bs-popover-bottom .popover-arrow::before, .popover-gray.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-gray.bs-popover-bottom .popover-arrow::after, .popover-gray.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::after {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-gray.bs-popover-bottom .popover-header::before, .popover-gray.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-gray .popover-header {
  --bs-popover-header-bg: #6C757D;
  border-bottom-color: var(--bs-popover-header-bg);
  --bs-popover-header-color: #FFFFFF;
}

.border-gray-dark {
  border-color: #343A40;
}

.popover-gray-dark {
  --bs-popover-border-color: #343A40;
  --bs-popover-arrow-border: #343A40;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
}
.popover-gray-dark.bs-popover-start .popover-arrow::before, .popover-gray-dark.bs-popover-auto[data-popper-placement^=start] .popover-arrow::before, .popover-gray-dark.bs-popover-end .popover-arrow::before, .popover-gray-dark.bs-popover-auto[data-popper-placement^=end] .popover-arrow::before {
  border-left-color: var(--bs-popover-arrow-border);
  border-right-color: var(--bs-popover-arrow-border);
}
.popover-gray-dark.bs-popover-top .popover-arrow::before, .popover-gray-dark.bs-popover-auto[data-popper-placement^=top] .popover-arrow::before {
  border-top-color: var(--bs-popover-arrow-border);
}
.popover-gray-dark.bs-popover-bottom .popover-arrow::before, .popover-gray-dark.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-gray-dark.bs-popover-bottom .popover-arrow::after, .popover-gray-dark.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::after {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-gray-dark.bs-popover-bottom .popover-header::before, .popover-gray-dark.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-gray-dark .popover-header {
  --bs-popover-header-bg: #343A40;
  border-bottom-color: var(--bs-popover-header-bg);
  --bs-popover-header-color: #FFFFFF;
}

.border-primary {
  border-color: #0D6EFD;
}

.popover-primary {
  --bs-popover-border-color: #0D6EFD;
  --bs-popover-arrow-border: #0D6EFD;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
}
.popover-primary.bs-popover-start .popover-arrow::before, .popover-primary.bs-popover-auto[data-popper-placement^=start] .popover-arrow::before, .popover-primary.bs-popover-end .popover-arrow::before, .popover-primary.bs-popover-auto[data-popper-placement^=end] .popover-arrow::before {
  border-left-color: var(--bs-popover-arrow-border);
  border-right-color: var(--bs-popover-arrow-border);
}
.popover-primary.bs-popover-top .popover-arrow::before, .popover-primary.bs-popover-auto[data-popper-placement^=top] .popover-arrow::before {
  border-top-color: var(--bs-popover-arrow-border);
}
.popover-primary.bs-popover-bottom .popover-arrow::before, .popover-primary.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-primary.bs-popover-bottom .popover-arrow::after, .popover-primary.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::after {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-primary.bs-popover-bottom .popover-header::before, .popover-primary.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-primary .popover-header {
  --bs-popover-header-bg: #0D6EFD;
  border-bottom-color: var(--bs-popover-header-bg);
  --bs-popover-header-color: #FFFFFF;
}

.border-secondary {
  border-color: #6C757D;
}

.popover-secondary {
  --bs-popover-border-color: #6C757D;
  --bs-popover-arrow-border: #6C757D;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
}
.popover-secondary.bs-popover-start .popover-arrow::before, .popover-secondary.bs-popover-auto[data-popper-placement^=start] .popover-arrow::before, .popover-secondary.bs-popover-end .popover-arrow::before, .popover-secondary.bs-popover-auto[data-popper-placement^=end] .popover-arrow::before {
  border-left-color: var(--bs-popover-arrow-border);
  border-right-color: var(--bs-popover-arrow-border);
}
.popover-secondary.bs-popover-top .popover-arrow::before, .popover-secondary.bs-popover-auto[data-popper-placement^=top] .popover-arrow::before {
  border-top-color: var(--bs-popover-arrow-border);
}
.popover-secondary.bs-popover-bottom .popover-arrow::before, .popover-secondary.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-secondary.bs-popover-bottom .popover-arrow::after, .popover-secondary.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::after {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-secondary.bs-popover-bottom .popover-header::before, .popover-secondary.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-secondary .popover-header {
  --bs-popover-header-bg: #6C757D;
  border-bottom-color: var(--bs-popover-header-bg);
  --bs-popover-header-color: #FFFFFF;
}

.border-success {
  border-color: #198754;
}

.popover-success {
  --bs-popover-border-color: #198754;
  --bs-popover-arrow-border: #198754;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
}
.popover-success.bs-popover-start .popover-arrow::before, .popover-success.bs-popover-auto[data-popper-placement^=start] .popover-arrow::before, .popover-success.bs-popover-end .popover-arrow::before, .popover-success.bs-popover-auto[data-popper-placement^=end] .popover-arrow::before {
  border-left-color: var(--bs-popover-arrow-border);
  border-right-color: var(--bs-popover-arrow-border);
}
.popover-success.bs-popover-top .popover-arrow::before, .popover-success.bs-popover-auto[data-popper-placement^=top] .popover-arrow::before {
  border-top-color: var(--bs-popover-arrow-border);
}
.popover-success.bs-popover-bottom .popover-arrow::before, .popover-success.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-success.bs-popover-bottom .popover-arrow::after, .popover-success.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::after {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-success.bs-popover-bottom .popover-header::before, .popover-success.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-success .popover-header {
  --bs-popover-header-bg: #198754;
  border-bottom-color: var(--bs-popover-header-bg);
  --bs-popover-header-color: #FFFFFF;
}

.border-info {
  border-color: #0DCAF0;
}

.popover-info {
  --bs-popover-border-color: #0DCAF0;
  --bs-popover-arrow-border: #0DCAF0;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
}
.popover-info.bs-popover-start .popover-arrow::before, .popover-info.bs-popover-auto[data-popper-placement^=start] .popover-arrow::before, .popover-info.bs-popover-end .popover-arrow::before, .popover-info.bs-popover-auto[data-popper-placement^=end] .popover-arrow::before {
  border-left-color: var(--bs-popover-arrow-border);
  border-right-color: var(--bs-popover-arrow-border);
}
.popover-info.bs-popover-top .popover-arrow::before, .popover-info.bs-popover-auto[data-popper-placement^=top] .popover-arrow::before {
  border-top-color: var(--bs-popover-arrow-border);
}
.popover-info.bs-popover-bottom .popover-arrow::before, .popover-info.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-info.bs-popover-bottom .popover-arrow::after, .popover-info.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::after {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-info.bs-popover-bottom .popover-header::before, .popover-info.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-info .popover-header {
  --bs-popover-header-bg: #0DCAF0;
  border-bottom-color: var(--bs-popover-header-bg);
  --bs-popover-header-color: #000000;
}

.border-warning {
  border-color: #FFC107;
}

.popover-warning {
  --bs-popover-border-color: #FFC107;
  --bs-popover-arrow-border: #FFC107;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
}
.popover-warning.bs-popover-start .popover-arrow::before, .popover-warning.bs-popover-auto[data-popper-placement^=start] .popover-arrow::before, .popover-warning.bs-popover-end .popover-arrow::before, .popover-warning.bs-popover-auto[data-popper-placement^=end] .popover-arrow::before {
  border-left-color: var(--bs-popover-arrow-border);
  border-right-color: var(--bs-popover-arrow-border);
}
.popover-warning.bs-popover-top .popover-arrow::before, .popover-warning.bs-popover-auto[data-popper-placement^=top] .popover-arrow::before {
  border-top-color: var(--bs-popover-arrow-border);
}
.popover-warning.bs-popover-bottom .popover-arrow::before, .popover-warning.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-warning.bs-popover-bottom .popover-arrow::after, .popover-warning.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::after {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-warning.bs-popover-bottom .popover-header::before, .popover-warning.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-warning .popover-header {
  --bs-popover-header-bg: #FFC107;
  border-bottom-color: var(--bs-popover-header-bg);
  --bs-popover-header-color: #000000;
}

.border-danger {
  border-color: #DC3545;
}

.popover-danger {
  --bs-popover-border-color: #DC3545;
  --bs-popover-arrow-border: #DC3545;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
}
.popover-danger.bs-popover-start .popover-arrow::before, .popover-danger.bs-popover-auto[data-popper-placement^=start] .popover-arrow::before, .popover-danger.bs-popover-end .popover-arrow::before, .popover-danger.bs-popover-auto[data-popper-placement^=end] .popover-arrow::before {
  border-left-color: var(--bs-popover-arrow-border);
  border-right-color: var(--bs-popover-arrow-border);
}
.popover-danger.bs-popover-top .popover-arrow::before, .popover-danger.bs-popover-auto[data-popper-placement^=top] .popover-arrow::before {
  border-top-color: var(--bs-popover-arrow-border);
}
.popover-danger.bs-popover-bottom .popover-arrow::before, .popover-danger.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-danger.bs-popover-bottom .popover-arrow::after, .popover-danger.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::after {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-danger.bs-popover-bottom .popover-header::before, .popover-danger.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-danger .popover-header {
  --bs-popover-header-bg: #DC3545;
  border-bottom-color: var(--bs-popover-header-bg);
  --bs-popover-header-color: #FFFFFF;
}

.border-light {
  border-color: #F8F9FA;
}

.popover-light {
  --bs-popover-border-color: #F8F9FA;
  --bs-popover-arrow-border: #F8F9FA;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
}
.popover-light.bs-popover-start .popover-arrow::before, .popover-light.bs-popover-auto[data-popper-placement^=start] .popover-arrow::before, .popover-light.bs-popover-end .popover-arrow::before, .popover-light.bs-popover-auto[data-popper-placement^=end] .popover-arrow::before {
  border-left-color: var(--bs-popover-arrow-border);
  border-right-color: var(--bs-popover-arrow-border);
}
.popover-light.bs-popover-top .popover-arrow::before, .popover-light.bs-popover-auto[data-popper-placement^=top] .popover-arrow::before {
  border-top-color: var(--bs-popover-arrow-border);
}
.popover-light.bs-popover-bottom .popover-arrow::before, .popover-light.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-light.bs-popover-bottom .popover-arrow::after, .popover-light.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::after {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-light.bs-popover-bottom .popover-header::before, .popover-light.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-light .popover-header {
  --bs-popover-header-bg: #F8F9FA;
  border-bottom-color: var(--bs-popover-header-bg);
  --bs-popover-header-color: #000000;
}

.border-dark {
  border-color: #212529;
}

.popover-dark {
  --bs-popover-border-color: #212529;
  --bs-popover-arrow-border: #212529;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
}
.popover-dark.bs-popover-start .popover-arrow::before, .popover-dark.bs-popover-auto[data-popper-placement^=start] .popover-arrow::before, .popover-dark.bs-popover-end .popover-arrow::before, .popover-dark.bs-popover-auto[data-popper-placement^=end] .popover-arrow::before {
  border-left-color: var(--bs-popover-arrow-border);
  border-right-color: var(--bs-popover-arrow-border);
}
.popover-dark.bs-popover-top .popover-arrow::before, .popover-dark.bs-popover-auto[data-popper-placement^=top] .popover-arrow::before {
  border-top-color: var(--bs-popover-arrow-border);
}
.popover-dark.bs-popover-bottom .popover-arrow::before, .popover-dark.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-dark.bs-popover-bottom .popover-arrow::after, .popover-dark.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::after {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-dark.bs-popover-bottom .popover-header::before, .popover-dark.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom-color: var(--bs-popover-arrow-border);
}
.popover-dark .popover-header {
  --bs-popover-header-bg: #212529;
  border-bottom-color: var(--bs-popover-header-bg);
  --bs-popover-header-color: #FFFFFF;
}

.popover-default {
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--fc-event-border-color, #3788d8);
  border-color: var(--fc-event-border-color, #3788d8);
}
.popover-default.bs-popover-start .popover-arrow::before, .popover-default.bs-popover-auto[data-popper-placement^=start] .popover-arrow::before, .popover-default.bs-popover-end .popover-arrow::before, .popover-default.bs-popover-auto[data-popper-placement^=end] .popover-arrow::before {
  border-left-color: var(--fc-event-border-color, #3788d8);
  border-right-color: var(--fc-event-border-color, #3788d8);
}
.popover-default.bs-popover-top .popover-arrow::before, .popover-default.bs-popover-auto[data-popper-placement^=top] .popover-arrow::before {
  border-top-color: var(--fc-event-border-color, #3788d8);
}
.popover-default.bs-popover-bottom .popover-arrow::before, .popover-default.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::before {
  border-bottom-color: var(--fc-event-border-color, #3788d8);
}
.popover-default.bs-popover-bottom .popover-arrow::after, .popover-default.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::after {
  border-bottom-color: var(--fc-event-border-color, #3788d8);
}
.popover-default.bs-popover-bottom .popover-header::before, .popover-default.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom-color: var(--fc-event-border-color, #3788d8);
}
.popover-default .popover-header {
  background-color: var(--fc-event-border-color, #3788d8);
  border-bottom-color: var(--fc-event-border-color, #3788d8);
  color: var(--fc-event-text-color, #fff);
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.event-lightblue {
  --fc-event-bg-color: #63a4c9;
  --fc-event-border-color: #63a4c9;
  --fc-event-text-color: #000000;
}

.event-navy {
  --fc-event-bg-color: #0d2a49;
  --fc-event-border-color: #0d2a49;
  --fc-event-text-color: #FFFFFF;
}

.event-olive {
  --fc-event-bg-color: #64ad8d;
  --fc-event-border-color: #64ad8d;
  --fc-event-text-color: #000000;
}

.event-lime {
  --fc-event-bg-color: #34ff8d;
  --fc-event-border-color: #34ff8d;
  --fc-event-text-color: #000000;
}

.event-fuchsia {
  --fc-event-bg-color: #f341cb;
  --fc-event-border-color: #f341cb;
  --fc-event-text-color: #000000;
}

.event-maroon {
  --fc-event-bg-color: #e04980;
  --fc-event-border-color: #e04980;
  --fc-event-text-color: #000000;
}

.event-blue {
  --fc-event-bg-color: #0D6EFD;
  --fc-event-border-color: #0D6EFD;
  --fc-event-text-color: #FFFFFF;
}

.event-indigo {
  --fc-event-bg-color: #6610F2;
  --fc-event-border-color: #6610F2;
  --fc-event-text-color: #FFFFFF;
}

.event-purple {
  --fc-event-bg-color: #6F42C1;
  --fc-event-border-color: #6F42C1;
  --fc-event-text-color: #FFFFFF;
}

.event-pink {
  --fc-event-bg-color: #D63384;
  --fc-event-border-color: #D63384;
  --fc-event-text-color: #FFFFFF;
}

.event-red {
  --fc-event-bg-color: #DC3545;
  --fc-event-border-color: #DC3545;
  --fc-event-text-color: #FFFFFF;
}

.event-orange {
  --fc-event-bg-color: #FD7E14;
  --fc-event-border-color: #FD7E14;
  --fc-event-text-color: #000000;
}

.event-yellow {
  --fc-event-bg-color: #FFC107;
  --fc-event-border-color: #FFC107;
  --fc-event-text-color: #000000;
}

.event-green {
  --fc-event-bg-color: #198754;
  --fc-event-border-color: #198754;
  --fc-event-text-color: #FFFFFF;
}

.event-teal {
  --fc-event-bg-color: #20C997;
  --fc-event-border-color: #20C997;
  --fc-event-text-color: #000000;
}

.event-cyan {
  --fc-event-bg-color: #0DCAF0;
  --fc-event-border-color: #0DCAF0;
  --fc-event-text-color: #000000;
}

.event-black {
  --fc-event-bg-color: #000000;
  --fc-event-border-color: #000000;
  --fc-event-text-color: #FFFFFF;
}

.event-white {
  --fc-event-bg-color: #FFFFFF;
  --fc-event-border-color: #FFFFFF;
  --fc-event-text-color: #000000;
}

.event-gray {
  --fc-event-bg-color: #6C757D;
  --fc-event-border-color: #6C757D;
  --fc-event-text-color: #FFFFFF;
}

.event-gray-dark {
  --fc-event-bg-color: #343A40;
  --fc-event-border-color: #343A40;
  --fc-event-text-color: #FFFFFF;
}

.event-primary {
  --fc-event-bg-color: #0D6EFD;
  --fc-event-border-color: #0D6EFD;
  --fc-event-text-color: #FFFFFF;
}

.event-secondary {
  --fc-event-bg-color: #6C757D;
  --fc-event-border-color: #6C757D;
  --fc-event-text-color: #FFFFFF;
}

.event-success {
  --fc-event-bg-color: #198754;
  --fc-event-border-color: #198754;
  --fc-event-text-color: #FFFFFF;
}

.event-info {
  --fc-event-bg-color: #0DCAF0;
  --fc-event-border-color: #0DCAF0;
  --fc-event-text-color: #000000;
}

.event-warning {
  --fc-event-bg-color: #FFC107;
  --fc-event-border-color: #FFC107;
  --fc-event-text-color: #000000;
}

.event-danger {
  --fc-event-bg-color: #DC3545;
  --fc-event-border-color: #DC3545;
  --fc-event-text-color: #FFFFFF;
}

.event-light {
  --fc-event-bg-color: #F8F9FA;
  --fc-event-border-color: #F8F9FA;
  --fc-event-text-color: #000000;
}

.event-dark {
  --fc-event-bg-color: #212529;
  --fc-event-border-color: #212529;
  --fc-event-text-color: #FFFFFF;
}

.toasts-top-right,
.toasts-top-left,
.toasts-bottom-right,
.toasts-bottom-right {
  margin: 0.5rem;
  z-index: 1050;
}

.select2-container--bootstrap5 .select2-results > .select2-results__options {
  max-height: calc((1.3125rem + 0.25rem * 2) * var(--select2-options-limit));
}
.select2-container--bootstrap5 .select2-selection {
  color: #212529;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
}
.select2-container--bootstrap5 .select2-results__group {
  --bs-dropdown-header-color: #6C757D;
  font-size: 0.765625rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}
.select2-container--bootstrap5.select2-container--focus.select2-container--open .select2-selection {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #86b7fe;
}
.select2-container--bootstrap5.select2-container--focus.select2-container--open.select2-container--above .select2-selection {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0.375rem;
}
.select2-container--bootstrap5 .select2-selection--multiple {
  border: 1px solid #CED4DA;
  min-height: calc(1.5em + 0.75rem + 2px);
  display: inline-block;
}
.select2-container--bootstrap5 .select2-selection--multiple:focus {
  border-color: #86b7fe;
}
.select2-container--bootstrap5 .select2-selection--multiple .select2-selection__rendered li:first-child.select2-search.select2-search--inline {
  width: 100%;
  margin-left: 0.375rem;
}
.select2-container--bootstrap5 .select2-selection--multiple .select2-selection__rendered li:first-child.select2-search.select2-search--inline .select2-search__field {
  width: 100% !important;
}
.select2-container--bootstrap5 .select2-selection--multiple .select2-selection__rendered .select2-selection__choice {
  background-color: #0D6EFD;
  border-color: #0262ef;
  border-radius: 0.375rem;
  color: #FFFFFF;
  display: inline-block;
  margin-left: 0 /*rtl:ignore*/;
  margin-top: 5px;
  padding: 0;
}
.select2-container--bootstrap5 .select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__display {
  display: inline-block;
  cursor: default;
  padding-left: 0.5rem;
  padding-right: 1.5rem;
}
.select2-container--bootstrap5 .select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove {
  background-color: transparent;
  border: none;
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  padding: 0 4px;
  left: auto;
  right: 0;
}
.select2-container--bootstrap5 .select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove:hover {
  color: #FFFFFF;
  outline: none;
}
.select2-container--bootstrap5 .select2-selection--multiple .select2-search.select2-search--inline {
  width: auto;
  display: inline-block;
}
.select2-container--bootstrap5 .select2-selection--multiple .select2-search.select2-search--inline .select2-search__field {
  border: 0;
}
.text-sm .select2-container--bootstrap5 .select2-selection--multiple .select2-search.select2-search--inline .select2-search__field, .select2-container--bootstrap5 .select2-selection--multiple.text-sm .select2-search.select2-search--inline .select2-search__field {
  margin-top: 8px;
}
.text-sm .select2-container--bootstrap5 .select2-selection--multiple .select2-selection__choice, .select2-container--bootstrap5 .select2-selection--multiple.text-sm .select2-selection__choice {
  margin-top: 0.4rem;
}
.select2-container--bootstrap5.select2-container--focus .select2-selection--single,
.select2-container--bootstrap5.select2-container--focus .select2-selection--multiple {
  border-color: #86b7fe;
}
.select2-container--bootstrap5.select2-container--focus .select2-search__field {
  border: 0;
}
.select2-container--bootstrap5 .ew-select-dropdown.select2-dropdown.select2-dropdown--below, .select2-container--bootstrap5 .ew-filter-dropdown.select2-dropdown.select2-dropdown--below {
  border-top: 1px solid #CED4DA;
}
.select2-container--bootstrap5 .ew-select-dropdown .select2-results__options[role=listbox], .select2-container--bootstrap5 .ew-filter-dropdown .select2-results__options[role=listbox] {
  width: 100%;
}
.select2-container--bootstrap5 .ew-select-dropdown .select2-results__option[role=option], .select2-container--bootstrap5 .ew-filter-dropdown .select2-results__option[role=option] {
  white-space: nowrap !important;
}
.select2-container--bootstrap5 .ew-select-dropdown.ew-select-multiple.ew-repeat-column .select2-results__options[role=listbox], .select2-container--bootstrap5 .ew-filter-dropdown.ew-select-multiple.ew-repeat-column .select2-results__options[role=listbox] {
  border-collapse: separate;
  border-spacing: 2px;
}
.select2-container--bootstrap5 .ew-select-dropdown div.select2-results__option[role=option]:not(:only-child), .select2-container--bootstrap5 .ew-filter-dropdown div.select2-results__option[role=option]:not(:only-child) {
  border-radius: 0.375rem;
}
.select2-container--bootstrap5 .ew-select-dropdown div.select2-results__option[role=option].loading-results, .select2-container--bootstrap5 .ew-filter-dropdown div.select2-results__option[role=option].loading-results {
  padding-left: 1.625em;
}
.select2-container--bootstrap5 .ew-select-dropdown.ew-select-one .select2-results__option[role=option], .select2-container--bootstrap5 .ew-filter-dropdown.ew-select-one .select2-results__option[role=option] {
  padding: 0.25rem 1rem;
}
.select2-container--bootstrap5 .ew-select-dropdown .ew-dropdown-check-input, .select2-container--bootstrap5 .ew-filter-dropdown .ew-dropdown-check-input {
  display: inline-block;
  margin-right: 0.5rem;
  background-color: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 0.25em;
}
.select2-container--bootstrap5 .ew-select-dropdown .select2-results__option--selected .ew-dropdown-check-input, .select2-container--bootstrap5 .ew-filter-dropdown .select2-results__option--selected .ew-dropdown-check-input {
  background-color: #0D6EFD;
  border-color: #0D6EFD;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.select2-container--bootstrap5 .ew-filter-dropdown {
  border-bottom-right-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important;
}
.select2-container--bootstrap5 .ew-filter-dropdown .select2-search--dropdown {
  padding: 6px;
}
.select2-container--bootstrap5 .ew-filter-dropdown .select2-search__field {
  width: 100% !important;
}
.select2-container--bootstrap5 .ew-filter-dropdown .select2-results > .select2-results__options {
  min-height: calc((1.3125rem + 0.25rem * 2) * var(--filter-options-limit));
  max-height: calc((1.3125rem + 0.25rem * 2) * var(--filter-options-limit));
}
.select2-container--bootstrap5 .ew-modal-dropdown .select2-search--dropdown {
  padding: 6px;
}
.select2-container--bootstrap5 .ew-modal-dropdown .select2-search__field {
  width: 100% !important;
}
.select2-container--bootstrap5 .select2-dropdown .select2-results__option--selected,
.select2-container--bootstrap5 .select2-dropdown .select2-results__option[aria-selected=true] {
  color: #1e2125;
  text-decoration: none;
  background-color: #E9ECEF;
}
.select2-container--bootstrap5 .select2-dropdown .select2-results__option--selected.select2-results__option--highlighted,
.select2-container--bootstrap5 .select2-dropdown .select2-results__option[aria-selected=true].select2-results__option--highlighted {
  color: #FFFFFF;
  text-decoration: none;
  background-color: #0D6EFD;
}

.ew-column-dropdown .dropdown-item {
  padding-left: 1rem;
  padding-right: 1rem;
}
.ew-column-dropdown .dropdown-item.active, .ew-column-dropdown .dropdown-item:active {
  color: #1e2125;
  background-color: #E9ECEF;
}
.ew-column-dropdown .form-check.ew-dropdown-checkbox .form-check-input {
  background-color: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 0.25em;
}
.ew-column-dropdown .form-check.ew-dropdown-checkbox .form-check-input.ew-checked {
  background-color: #0D6EFD;
  border-color: #0D6EFD;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

.modal-header > .modal-tools {
  float: right;
  margin-right: -0.5rem;
}
.modal-header > .modal-tools .input,
.modal-header > .modal-tools .input-group {
  margin: 0;
}
.modal-header > .modal-tools [data-bs-toggle=tooltip] {
  position: relative;
}

.template-download .error,
.template-upload .error {
  font-size: 0.875em;
  color: #DC3545;
}

.ew-search-field > .invalid-feedback,
.ew-search-field2 > .invalid-feedback {
  margin-left: 1em;
}

.form-control-navbar:focus, .btn-navbar:focus {
  box-shadow: none;
}

.ew-col-break + .col-auto {
  margin-bottom: 1rem;
}

mark.ew-mark:not(.mark) {
  padding: 0;
  background: transparent;
}

.modal-body .select2-container {
  width: 100%;
}
.modal-body .select2-dropdown {
  border: 0;
  border-radius: 0;
  left: auto !important;
  position: static !important;
}

.modal-body .select2-container--bootstrap5 .select2-results > .select2-results__options {
  min-height: calc((1.3125rem + 0.375rem + 0.375rem) * var(--modal-options-limit));
  max-height: calc((1.3125rem + 0.375rem + 0.375rem) * var(--modal-options-limit));
}

.ew-filter-field {
  display: none !important;
}

.ew-select, .select2-container {
  min-width: 20em !important;
}

.select2-container--open {
  z-index: 1035 !important;
}

.modal-body .select2-container--open {
  z-index: 1056 !important;
}

.w-1 {
  width: 1%;
}

.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.pace .pace-progress {
  background-color: #367FA9;
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 100%;
  width: 100%;
  height: 4px;
}

.pace-inactive {
  display: none;
}

.dropdown-divider {
  height: 0 !important;
}

.ew-file-input, .ew-file-input:focus {
  color: transparent;
}

.ew-file-drop-zone {
  display: block !important;
  border: 2px dashed #CED4DA;
  vertical-align: middle;
  text-align: center;
  margin-bottom: 0.5rem;
  border-radius: 0.375rem;
}
.ew-file-drop-zone .ew-file-text {
  line-height: 4;
}

a[data-ew-action]:not([data-ew-action=""]) {
  cursor: pointer;
}

.ew-backup-codes {
  height: 14.125rem;
}

img.ew-barcode {
  margin: 0.5rem;
}

.ew-no-record .ew-grid {
  display: none;
}

.vh-5 {
  height: 5vh;
}

.vh-10 {
  height: 10vh;
}

.vh-15 {
  height: 15vh;
}

.vh-20 {
  height: 20vh;
}

.vh-25 {
  height: 25vh;
}

.vh-30 {
  height: 30vh;
}

.vh-35 {
  height: 35vh;
}

.vh-40 {
  height: 40vh;
}

.vh-45 {
  height: 45vh;
}

.vh-50 {
  height: 50vh;
}

.vh-55 {
  height: 55vh;
}

.vh-60 {
  height: 60vh;
}

.vh-65 {
  height: 65vh;
}

.vh-70 {
  height: 70vh;
}

.vh-75 {
  height: 75vh;
}

.vh-80 {
  height: 80vh;
}

.vh-85 {
  height: 85vh;
}

.vh-90 {
  height: 90vh;
}

.vh-95 {
  height: 95vh;
}

.h-50px {
  height: 50px;
}

.mh-50px {
  max-height: 50px;
}

.h-100px {
  height: 100px;
}

.mh-100px {
  max-height: 100px;
}

.h-150px {
  height: 150px;
}

.mh-150px {
  max-height: 150px;
}

.h-200px {
  height: 200px;
}

.mh-200px {
  max-height: 200px;
}

.h-250px {
  height: 250px;
}

.mh-250px {
  max-height: 250px;
}

.h-300px {
  height: 300px;
}

.mh-300px {
  max-height: 300px;
}

.h-350px {
  height: 350px;
}

.mh-350px {
  max-height: 350px;
}

.h-400px {
  height: 400px;
}

.mh-400px {
  max-height: 400px;
}

.h-450px {
  height: 450px;
}

.mh-450px {
  max-height: 450px;
}

.h-500px {
  height: 500px;
}

.mh-500px {
  max-height: 500px;
}

.h-550px {
  height: 550px;
}

.mh-550px {
  max-height: 550px;
}

.h-600px {
  height: 600px;
}

.mh-600px {
  max-height: 600px;
}

.h-650px {
  height: 650px;
}

.mh-650px {
  max-height: 650px;
}

.h-700px {
  height: 700px;
}

.mh-700px {
  max-height: 700px;
}

.h-750px {
  height: 750px;
}

.mh-750px {
  max-height: 750px;
}

.h-800px {
  height: 800px;
}

.mh-800px {
  max-height: 800px;
}

.h-850px {
  height: 850px;
}

.mh-850px {
  max-height: 850px;
}

.h-900px {
  height: 900px;
}

.mh-900px {
  max-height: 900px;
}

.h-950px {
  height: 950px;
}

.mh-950px {
  max-height: 950px;
}

.h-1000px {
  height: 1000px;
}

.mh-1000px {
  max-height: 1000px;
}

.card .overlay {
  border-radius: 0;
}

.overlay-wrapper {
  position: relative;
  min-height: 3rem;
}

div[id^=dsl_] .ew-loading {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
  margin: 0.5rem !important;
}

.card .overlay,
.overlay-wrapper .overlay,
.info-box .overlay,
.small-box .overlay {
  background-color: rgba(255, 255, 255, 0.5);
}

.ew-calendar-wrapper {
  min-height: 6rem;
}

.btn-tool {
  --bs-btn-font-weight: 400;
  --bs-btn-color: #ADB5BD;
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: #495057;
  --bs-btn-hover-bg: transparent;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: #495057;
  --bs-btn-active-bg: transparent;
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #DEE2E6;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-box-shadow: none;
}

.btn:not([class*=btn-]) {
  --bs-btn-border-color: transparent;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-focus-shadow-rgb: 222, 226, 230;
}

.ew-calendar .fc-toolbar {
  flex-direction: row;
}
.ew-calendar .table-bordered {
  border: 1px solid var(--bs-border-color);
}
.ew-calendar .table-bordered td, .ew-calendar .table-bordered th {
  border: 1px solid var(--bs-border-color);
}

.ew-event-popover {
  min-width: 150px;
}

.ew-view-rules {
  max-height: 300px;
}

.main-header .dropdown-item {
  color: #212529 !important;
}
.main-header .dropdown-item:hover, .main-header .dropdown-item:focus {
  color: #1e2125 !important;
  text-decoration: none;
  background-color: #E9ECEF;
}
.main-header .dropdown-item.active, .main-header .dropdown-item:active {
  color: #FFFFFF !important;
  background-color: #0D6EFD;
}
.main-header .dropdown-item.disabled, .main-header .dropdown-item:disabled {
  color: #ADB5BD !important;
}

/*END_SYSTEM_STYLES*/
/*BEGIN_USER_STYLES*/
textarea.form-control {
  width: auto;
}

/*END_USER_STYLES*/
